import React from "react";
import "./Footer.css";
import Lottie from "lottie-react";
import twitter from "../Animations/twitter.json";
import instagram from "../Animations/instagram.json";
import facebook from "../Animations/facebook.json";

const Footer = () => {
  return (
    <footer>
      <a href="#" className="footer__logo">
        Ali Tech
      </a>
      <ul className="permalinks">
        <li>
          <a href="#">Home</a>
        </li>
        <li>
          <a href="#about">About</a>
        </li>
        <li>
          <a href="#experience">Experience</a>
        </li>
        <li>
          <a href="#services">Services</a>
        </li>
        <li>
          <a href="#portfolio">Portfolio</a>
        </li>
        <li>
          <a href="#testimonials">Testimonials</a>
        </li>
        <li>
          <a href="#contact">Contact</a>
        </li>
      </ul>

      <div className="footer__socials">
        <a
          href="https://www.facebook.com/profile.php?id=100094382199177"
          aria-label="Facebook Profile of alitech.pro"
          target="_blank"
        >
          <Lottie
            loop={true}
            animationData={facebook}
            style={{ width: "100%" }}
          />
        </a>
        <a href="https://www.instagram.com/alitech.pro/"
        aria-label="Instagram Profile of alitech.pro"
        target="_blank">
          <Lottie
            loop={true}
            animationData={instagram}
            style={{ width: "100%" }}
          />
        </a>
        <a href="https://twitter.com/AliTechpro"
        aria-label="Twitter Profile of alitech.pro"
        target="_blank">

          <Lottie
            loop={true}
            animationData={twitter}
            style={{ width: "100%" }}
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
