import React from 'react'
import { Helmet } from 'react-helmet-async'
import New_Background from '../../assets/New_Background.webp'

const Seo = ({title,description,Name,type,canonical}) => {
  return (
    <Helmet>
    { /* Standard metadata tags */ }
    <title>{title}</title>
    <meta name='description' content={description} />
    { /* End standard metadata tags */ }
    { /* Facebook tags */ }
    <meta property="og:type" content={type} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description}  data-rh="true"/>
    <meta property="og:image" content={New_Background } />
    <meta property="og:url" content="https://alitech.pro/"/>

    { /* End Facebook tags */ }
    { /* Twitter tags */ }
    <meta name="twitter:creator" content={Name} />
    <meta name="twitter:card" content={type} />
    <meta name="twitter:title" content={title} />
    <meta name="twitter:description" content={description} />
    { /* End Twitter tags */ }
    <link rel="canonical" href={canonical} />
    <meta name="keywords" content="FPGA,STM32,ESP32 ,PCB Layout,Electrical Engineer,Electronic Circuit Expert Embedded System, Electronic Circuit Design, Electrical Engineering, Analog Electronics, Multilayer PCB,Altium Designer,Microcontroller Programming,PCB Design,Hardware Design,Electronics,Digital Electronics,Electronic Design,Electrical Layout Design,Printed Circuit Board" />
    <meta name="author" content="Ali Uzair"/>
   
    </Helmet>
  )
}

export default Seo
