import React from 'react'
import './Header.css'
import CTA from './CTA'
import HeaderSocials from './HeaderSocials'
import HeaderSocials_Top from './HeaderSocials_Top'
import Socials from './Socials'
import Socials_Top from './Socials_Top'
import Mycomponent  from '../../components/Animations/Type/type'
import Mycomp from '../../components/Animations/Type/CTA'
import New_Background from '../../assets/New_Background.webp'
import Seo from '../SEO/Seo'


const Header = () => {
  return (
    <header>
    <Seo
    title='Electronic & Electrical Engineering Services For Next Unicorn💎'
    description='Looking For a Skilled Circuit Designer And Programmer❓ Look No Further As a Passionate Freelancer, I Offer Top-Notch Expertise In  Prototyping.'
    Name='Professional Engineer'
    type='article'
    canonical=''/>

    <div className="container header__container ">
    <h1>Hardware Developer</h1>
    <h2 className='text-design'>Specialist in  <Mycomponent /> </h2>
    <CTA/>
    <h3 className='intro'>
    Mastering The Power Of Innovation <br /> Through Electrical Engineering <br />Expert In Hardware Design <br /> & Firmware Development.<Mycomp/>
    </h3>
    <div className='Image'>
    <img src={New_Background} alt="Porfesional Circuit Designer Logo" title='Hardware Developer'/>
    </div>
    <HeaderSocials_Top/>
    <HeaderSocials/>
    <Socials_Top/>
    <Socials/>
    </div>  
    </header> 
  )
}

export default Header

