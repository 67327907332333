import React from "react";
import { BsTwitter } from "react-icons/bs";
import { BsFacebook} from "react-icons/bs";
import { BsInstagram } from "react-icons/bs";

const Socials = () => {
  return (
    <div className="socials">
      <a
        href="https://twitter.com/AliTechpro"
        aria-label="Check Out Alitech.pro On Twitter"
        target="_blank"
      >
      <BsTwitter />
      </a>
      <a href="https://www.facebook.com/profile.php?id=100094382199177&sk=about" 
      aria-label="Check Out Alitech.pro On Facebook"
      target="_blank">
      <BsFacebook />
      </a>
      <a href="https://www.instagram.com/alitech.pro/" target="_blank" aria-label="Check Out Alitech.pro On Instagram">
      <BsInstagram />
        
      </a>
    </div>
  );
};

export default Socials;
