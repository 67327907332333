import React from "react";
import Work from "./../../assets/Sample.rar";

const CTA = () => {
  return (
    <div className="CTA">
    <a href={Work} download className="btn">
    You will get
    </a>
    <a href="#contact" className="btn btn-primary">Let,s Talk </a>
    </div>
  );
};

export default CTA;
