import React from 'react'
import './services.css'
import { BiCheck } from 'react-icons/bi'

const services = () => {
  return (
    <section id='services'>
    <h4>What I offer</h4>
    <h2>Services</h2>

    <div className="container services__container">
    <article className='service'>
    <div className="service__head">
    <h3>Circuit Design</h3>
    </div>

    <ul className='service__list'>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Block Diagram and Design Documentation</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Schematic Design</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Circuit Simulation </p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>BOM Purchasing BOM Component Reference </p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Part Selection, Parts Sourcing</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Gerber Files</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Mechanical Drawing</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Assembly Drawing</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Pick And Place</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>PDF3D</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Compact PCB design</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>IPC Standard Design</p>  
    </li>
    </ul>
    </article>

    {/* ========== END OF Circuit Designer ========== */}

    <article className='service'>
    <div className="service__head">
    <h3>Hardware Developer</h3>
    </div>

    <ul className='service__list'>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>High Speed, High-Density Multi-Layer PCB Layout</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Analog Circuit Design: Audio, RF Module, PA, LCD, Filter, RFID</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Digital Circuit Design: DDR1~4, Flash, DSP, FPGA, Microchip</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Mixed Circuit Design: GPS, Bluetooth, WiFi, ZigBee, LoraWan, RF 43</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p> Interface Buses SPI, I2C, RS-232, RS-485,</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Memory Interfaces DDR1-3, SDRAM, Serial/Parallel Flash</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>High Speed I/O Interfaces USB, Ethernet, PCI Express, LVDS</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Power / Signal Integrity Analysis</p>  
    </li>
    </ul>
    </article>

    {/* ========== Embedded System ========== */}

    <article className='service'>
    <div className="service__head">
    <h3>Embedded System</h3>
    </div>

    <ul className='service__list'>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>IOT</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>STM32 Microcontrollers</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>ESP32 , ESP8266 Microcontrollers</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>PIC Microcontrollers</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>FPGA</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>prototyping</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>C/C+</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Verilog</p>  
    </li>
    <li>
    <BiCheck className='service__list-icon'/>
    <p>Python</p>  
    </li>
    </ul>
    </article>

    {/* ========== END OF CONTENT CREATION ========== */}
    </div>  
    </section>
  )
}

export default services