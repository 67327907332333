import React from 'react'
import './Nav.css'
import {AiOutlineHome} from 'react-icons/ai'
import {FaUserGraduate} from 'react-icons/fa'  
import {AiFillCrown} from 'react-icons/ai'
import {FaPenNib} from 'react-icons/fa'
import {FaTelegram} from 'react-icons/fa'
import {SiUpwork} from 'react-icons/si'
import { useState } from 'react'

const Nav = () => {
  const [activeNav , setActiveNav] = useState('#')
  return (
  <nav>
    <a href="#" aria-label="Home page of alitech.pro" onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active' : ''}><AiOutlineHome/></a>
    <a href="#about" aria-label="Information About Me" onClick={() => setActiveNav('#about')} className={activeNav === '#about' ? 'active' : ''}><FaUserGraduate/></a>
    <a href="#experience" aria-label="My past Experience" onClick={() => setActiveNav('#experience')} className={activeNav === '#experience' ? 'active' : ''}><AiFillCrown/></a>
    <a href="#services" aria-label="I offer these Services" onClick={() => setActiveNav('#services')} className={activeNav === '#services' ? 'active' : ''}><FaPenNib/></a>
    <a href="#contact" aria-label="Lets talk" onClick={() => setActiveNav('#contact')} className={activeNav === '#contact' ? 'active' : ''}><FaTelegram/></a> 
    <a href="https://www.upwork.com/freelancers/~0100c48383c4ecde11"
    aria-label="My Professional PCB Designer Upwork Profile Link"
    target='_blank' onClick={() => setActiveNav('#https://www.upwork.com/freelancers/~0100c48383c4ecde11')}><SiUpwork/></a> 
  </nav>
  )
}

export default Nav