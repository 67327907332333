import React from 'react'
import './Testimonials.css'
import Danial_Miller from '../../assets/Danial_Miller.webp'
import Salah from '../../assets/Salah.webp'
import Patrick_Spears from '../../assets/Patrick_Spears.webp'
import Am_Finn_Company from '../../assets/Am_Finn_Company.webp'
import Amir_El_Sawah from '../../assets/Amir_El_Sawah.webp'
import Craig_Easdale from '../../assets/Craig_Easdale.webp'
import Stanton_McComb from '../../assets/Stanton_McComb.webp'

// IMPORT SWIPER //
import { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


const data = [ 
{
  avatar: Danial_Miller,
  name: 'Solais Lighting Group',
  review: '   Danial Miller ❝  We have used Ali a number of times and he continues to do great work. I highly recommend him  ❞',
  ratings: '✪✪✪✪✪',
},
{
  avatar: Craig_Easdale,
  name: 'Craig Easdale',
  review: '❝  Working with Ali is amazing. He is a hard worker and gets things done quickly. We 100% trust him with PCB design  ❞',
  ratings: '✪✪✪✪✪',
},
{
  avatar: Amir_El_Sawah,
  name: 'Amir El Sawah',
  review: '❝  We have done several PCB projects with Ali, and have had zero complaints. He justifies each of his costs, always responds within 24 hours or sooner, and works quickly and effectively. If you have a PCB project, you cannot go wrong with Ali  ❞',
  ratings: '✪✪✪✪✪',
},
{
  avatar: Patrick_Spears,
  name: 'Patrick_Spears',
  review: '❝  Ali is very knowledgeable and through. He set us up for success, helping to define key milestones and deliverables. Without his involvement we would have not been able to make as much progress as we hoped for  ❞',
  ratings: '✪✪✪✪✪',
},
{
  avatar: Am_Finn_Company,
  name: 'Am-Finn Company',
  review: '❝  We have done several PCB projects with Ali, and have had zero complaints. He justifies each of his costs, always responds within 24 hours or sooner, and works quickly and effectively. If you have a PCB project, you cannot go wrong with Ali  ❞',
  ratings: '✪✪✪✪✪',
},
{
  avatar: Stanton_McComb,
  name: 'Stanton McComb',
  review: '❝  Ali is very reliable professional who focuses a lot on the quality of his work Ali was very responsive and helpful, and I was really impress by the quality of work I would defiantly recommend Ali for any one want to get the job done very professionally and in short time  ❞',
  ratings: '✪✪✪✪✪',
},
{
  avatar: Salah,
  name: 'Mohamed Abuaziza',
  review: '❝  Ali was very professional and always available to help. He did an amazing job in very short time. it was my pleasure working with Ali, and I am looking forward to work with hime again. Highly recommend if you want your job done professionally  ❞',
  ratings: '✪✪✪✪✪',
},
]

const Testimonials = () => {
  return (
    <section id='testimonials'>
    <h4>Review from clients</h4>
    <h2>Testimonials</h2> 

    <Swiper className="container testimonials__container mySwiper"
     spaceBetween={30}
     centeredSlides={true}
     autoplay={{
       delay: 12000,
       disableOnInteraction: false,
     }}
     pagination={{
       clickable: true,
     }}
     navigation={true}
     modules={[Autoplay, Pagination, Navigation]}
    >
    {
    data.map(({avatar,name,review,ratings},index) => {
    return (
    <SwiperSlide key={index} className="testimonial">
    <div className="client__avator">
    <img src={avatar} alt={review} title='Professional Circuit Designer Testimonials' loading='lazy'/>
    </div> 
    <h3 className='client__name'>{name}</h3>
    <small className='client__review'>{review}</small>
    <small className='client__ratings'>{ratings}</small>
    </SwiperSlide>
    )
    })
    }
    </Swiper> 
    </section>
  )
}

export default Testimonials








