import React from 'react'
import './Experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'

const Experience = () => {
  return (
    <section id='experience'>
    <h4>What Skills I have</h4>
    <h2>My Experience</h2>

    <div className="container experience__container">
    <div className="experience__frontend">
    <h3>Circuit Designer</h3>
    <div className="experience__content">

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>HyperLynx</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article> 

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>Altium Designer</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article> 

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>Matlab</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article>  

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>Proteus Design Suite</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article>  

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>Simulink</h4>
    <small className='text-light'>Intermediate </small>
    </div>
    </article>  

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>ADS</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article>  
    </div>
    </div>

    {/* ========= BACKEND DEVELOPER ========== */}
    
      
    <div className="experience__backend">
    <h3>Programmer</h3>
    <div className="experience__content">

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>C/C+</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article> 

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>Python</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article> 

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>JavaScript</h4>
    <small className='text-light'>Intermediate</small>
    </div>
    </article>  

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>Verilog</h4>
    <small className='text-light'>Experienced</small>
    </div>
    </article>  

    <article className='experience__details'>
    <BsPatchCheckFill className='experience__details-icon'/>
    <div>
    <h4>VHDL</h4>
    <small className='text-light'>Intermediate</small>
    </div>
    </article>  
  
    </div>  
    </div>  
    </div>  
    </section>
  )
}

export default Experience