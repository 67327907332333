import React from 'react'
import './Contact.css'
import {MdOutlineEmail} from 'react-icons/md'
import {RiMessengerLine} from 'react-icons/ri'
import {BsWhatsapp} from 'react-icons/bs'
import { useRef } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef();
  const sendEmail = (e) => {
  e.preventDefault();

  emailjs.sendForm('service_l0gpkem', 'template_6b7o0t9', form.current, 'uFElTNwoLsmaqXFXF')
  e.target.reset();

  };
  return (
    <section id='contact'>
    <h4>Get In Touch</h4>
    <h2>Contect Me</h2>  

    <div className='container contact__container'>
      <div className="contact__options">

      <article className="contact__option">
      <MdOutlineEmail className='contact__option-icon'/>
      <h3>Email</h3>
      <h4>AliTech Pro</h4>
      <a href="mailto:alitech.pro@hotmail.com" target='_blank'>Send a message</a>  
      </article>

      <article className="contact__option">
      <RiMessengerLine className='contact__option-icon'/>
      <h3>Messenger</h3>
      <h4>AliTech Pro</h4>
      <a href="https://www.messenger.com/t/100094382199177/" target='_blank'>Send a message</a>  
      </article>

      <article className="contact__option">
      <BsWhatsapp className='contact__option-icon'/>
      <h3>Whatsapp</h3>
      <h4>AliTech Pro</h4>
      <a href="https://api.whatsapp.com/send?phone=923332331666" target='_blank'>Send a message</a>  
      </article>

      </div>
      {/* {END OF CONTACT OPTIONS} */}
      <form ref = {form} onSubmit={sendEmail}>
      <input type="text" name='name' placeholder='Your Full Name' required/>
      <input type="email" name='email' placeholder='Your Email' required/>
      <textarea name="message" rows="7" placeholder='Your Message' required></textarea>
      <button type='submit' className='btn btn-primary'>Send Message</button>
      </form>
    </div>

    </section>
  )
}

export default Contact