import React from 'react'
import './About.css'
import ME from '../../assets/Me.webp'
import { FaAward } from 'react-icons/fa'    
import { FiUsers } from 'react-icons/fi'    
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
    <h4>Get To know</h4>
    <h2>About Me</h2>  
    <div className='container about__container'>
    <div className='about__me'>
    <div className='about__me-image'>
    <img src={ME} alt="Professional Hardware Developer Profile Pic" title='Electronics Engineer'/>
    </div>
    </div>  
    <div className='about__content'>
    <div className='about__cards'>
    <article className='about__card'>
    <FaAward className='about__icon'/>
    <h3 className='ali'>Experience</h3>
    <small>8+ Years Working</small>
    </article>
    <article className='about__card'>
    <FiUsers className='about__icon'/>
    <h3 className='ali'>Clients</h3>
    <small>100+ Worldwide</small>
    </article> 
    <article className='about__card'>
    <VscFolderLibrary className='about__icon'/>
    <h3 className='ali'>Projetcs</h3>
    <small>200+ Completed</small>
    </article>  
    </div>
    <div className='content'> 
    <h4 className='explain'>
    Looking for a Skilled and reliable PCB Designer to bring your Product Ideas to Life❓ Look No further! <br />
    </h4>
    <h4 className='explain'>
    ✪ I'm Ali Uzair, an Experienced Electrical Engineer Specializing in Designing Electronic Circuits and Multi-Layer PCBs ✪ 
    </h4>
    <h4 className='explain'> 
    ✪ With a strong background in Altium Designer, I have the Expertise to create Optimal PCB layouts that meet your Specific requirements.
    </h4>
    <h4 className='explain'>
    I prioritize customer satisfaction and maintain the highest level of Professionalism throughout every project ✪  </h4>
    <h4 className='explain'>
    ✪ By adhering to strict ICO guidelines and standards, I ensure that you receive Top-quality results in the shortest possible time.
    </h4>
    <h4 className='explain'> 
    I firmly believe that a flawless PCB design is crucial for the overall functionality and success of any Electronic Product ✪ 
    </h4>
    <h4 className='explain'> ✪If you're ready to transform your Ideas into valuable Products, don't Hesitate to Contact Me 📞</h4>
    <h4 className='explain'>
    💎 Together, we can create the perfect PCB design for your product. Let's bring your vision to life! 💎</h4> 
    </div>
    <a href="#contact" className='btn btn-primary'>Let,s Talk</a>
    </div>
    </div>
    </section>
  )
}

export default About