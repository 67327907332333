import React from 'react'
import './Portfolio.css'
import IMG1 from '../../assets/portfolio1.webp'
import IMG2 from '../../assets/portfolio2.webp'
import IMG3 from '../../assets/portfolio3.webp'
import IMG4 from '../../assets/portfolio4.webp'
import IMG5 from '../../assets/portfolio5.webp'
import IMG6 from '../../assets/portfolio6.webp'




const data = [
{ id: 1,
  image: IMG1,
  title: 'PCB Design for Multi-Purpose Cleaning Machine',
  PCB_Details: 'https://drive.google.com/drive/folders/1nloRGiNl8R448UY92aKRClirgWqJ_TLc?usp=sharing',
  demo: 'https://www.upwork.com/services/product/design-professional-multi-layer-pcb-design-for-your-product-1616307231492296704?ref=project_share'
},
 {id: 2,
  image: IMG2,
  title: 'PCB Design Of Ozone Level Detector',
  PCB_Details: 'https://drive.google.com/drive/folders/1d80-260ptJ3h_K78niIaZliem3oBHL8O?usp=sharing',
  demo: 'https://www.upwork.com/freelancers/~0100c48383c4ecde11#portfolio'
},
{ id: 3,
  image: IMG3,
  title: 'High-Quality Audio Board PCB Design',
  PCB_Details: 'https://drive.google.com/drive/folders/11Tb1tCuNWmaUcCGZpc-rFg711zX34zzj?usp=sharing',
  demo: 'https://www.upwork.com/services/product/design-professional-multi-layer-pcb-design-for-your-product-1616307231492296704?ref=project_share'
},
{ id: 4,
  image: IMG4,
  title: 'PCB Panel Design',
  PCB_Details: 'https://www.upwork.com/services/product/design-professional-multi-layer-pcb-design-for-your-product-1616307231492296704?ref=project_share',
  demo: 'https://www.upwork.com/freelancers/~0100c48383c4ecde11#portfolio'
},
{ id: 5,
  image: IMG5,
  title: 'PCB Design of RGB Controller',
  PCB_Details: 'https://drive.google.com/drive/folders/1GWg5UTuy2nZ4fSv7IFBGh9trRJb_cV4a?usp=sharing',
  demo: 'https://www.upwork.com/services/product/design-professional-multi-layer-pcb-design-for-your-product-1616307231492296704?ref=project_share'
},
{ id: 6,
  image: IMG6,
  title: 'PCB Design Of RGB Amplifier',
  PCB_Details: 'https://drive.google.com/drive/folders/10o6yDG8dkHn-m3bSyNO3wJnGVwGr5ekw?usp=sharing',
  demo: 'https://www.upwork.com/freelancers/~0100c48383c4ecde11#portfolio'
}
]

const Portfolio = () => {
  return (
    <section id='portfolio'>
    <h4>My Recent Work</h4>
    <h2>Portfolio</h2>

    <div className="container portfolio__container">
    {
    data.map(({id, image, title, PCB_Details, demo}) => {
    return(
    <article key={id} className='portfolio__item'>
    <div className="portfolio__item-image">
    <img src={image} alt= {title} title='Experienced Electronics Engineer Portfolio' loading='lazy'/>
    </div>
    <h3>{title}</h3>
    <div className='portfolio__item-cta'>
    <a href= {PCB_Details} className='btn' target='_blank'>PCB 3D</a>
    <a href={demo} className='btn btn-primary' target='_blank'>Up Work</a>
    </div>
    </article>
    )
    })
    }
    </div>  
    </section>
  )
}

export default Portfolio


