import React from "react";
import { BsLinkedin } from "react-icons/bs";
import { FaGithub } from "react-icons/fa";
import { SiUpwork } from "react-icons/si";

const HeaderSocials_Top = () => {
  return (
    <div className="header__socials__Top">
      <a
        href="https://www.upwork.com/freelancers/~0100c48383c4ecde11"
        target="_blank"
        aria-label="Read more about Me on Upwork"
      >
      <SiUpwork />
      </a>
      <a href="https://www.linkedin.com/in/ali-uzair-85279a19a" target="_blank" aria-label="Read more about Me on linkedin">
      <BsLinkedin />
      </a>
      <a href="https://github.com/Alitechpro" target="_blank" aria-label="Read more about Me on github">
      <FaGithub />
      </a>
    </div>
  );
};

export default HeaderSocials_Top;
